import config from "./config"
import {
	CognitoUserPool,
	CognitoUserAttribute,
	CognitoUser,
	AuthenticationDetails
} from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';
import { navigate } from "gatsby"


/**
 * Authentication is done through AWS Cognito. The functions in this module are helpers for 
 * registration, confirmation and authentication through the AWS API for Cognito.
 */



/**
 * Get a user object for this session
 * @returns Object user
 */
export const getUser = () => {
	let user = {}
	if(typeof window !== 'undefined') {
   	user = window.localStorage.getItem("ewsUser")
    	? JSON.parse(window.localStorage.getItem("ewsUser"))
    	: {}
		if(window.location.hostname === "localhost"){
			user = {token: "empty", email: "test@test.nl" , name: "test", groups: ["Administrator"], aws_id: 123, userType: "Regular"}
		}
	}

	return user;
}

export const getToken = () => {
	const user = getUser()
	return user.token ? user.token : null
}

/**
 * Store the user in session
 * @param {Object} user 
 * @returns 
 */
export const setUser = (user) => {
	if(typeof window !== 'undefined') {
		let userType = window.localStorage.getItem("userType")
		if(!userType){
			userType = "Regular"
		}
		user.userType = userType
		window.localStorage.setItem("ewsUser", JSON.stringify(user))
	}
}

export const handleLogin = (username, password, callback) => {
	const authenticationData = {
		Username: username,
		Password: password,
	};
	
	const authenticationDetails = new AuthenticationDetails(
		authenticationData
	);
	
	const cognitoUser = getCognitoUser(username)

	let response = {
		success: false,
		message: "",
		error: "",
		user: {}
	}

	cognitoUser.authenticateUser(authenticationDetails, {
		onSuccess: function(result) {
			//const accessToken = result.getAccessToken().getJwtToken();
			let accessToken = result.getIdToken().getJwtToken()
	
			//POTENTIAL: Region needs to be set if not already set previously elsewhere.
			AWS.config.region = config.aws.region;
	
			AWS.config.credentials = new AWS.CognitoIdentityCredentials({
				IdentityPoolId: config.aws.identity_pool_id,
				RoleArn: config.aws.auth_role_arn,
				AccountId: config.aws.account_id,
				Logins: {
					// Change the key below according to the specific region your user pool is in.
						'cognito-idp.us-east-1.amazonaws.com/us-east-1_iP4aEGt0G': result
						.getIdToken()
						.getJwtToken(),
				},
			});

			//refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
			let groups = []
			AWS.config.credentials.refresh(error => {
				if (error) {
					response.error = error.message

					callback(response)
				} else {
					cognitoUser.getSession(function(err, result3) {
						accessToken = result3.accessToken.jwtToken;
						if(typeof(result3.accessToken.payload['cognito:groups']) !== 'undefined') {
							groups = result3.accessToken.payload['cognito:groups']
						}
						
						cognitoUser.getUserAttributes(function(err, result2) {
							if (err) {
								response.error = error.message;
								callback(response);
							} else {
								response.success = true
								response.message = "You have successfully logged in and will be redirected to the dashboard in a moment!"
								let user = {aws_id: "", name: "", email: ""}
								let email_verified = false;
								for(let i=0; i<result2.length; i++) {
									let field = result2[i];
									switch(field.getName()) {
										case 'sub':
											user.aws_id = field.getValue();
											break;
										case 'email_verified':
											email_verified = (field.getValue() == 'true');
											break;
										case 'name':
										case 'email':
											user[field.getName()] = field.getValue();
											break;
										default:
											break;
									}
								}
								if(email_verified == false) {
									response.error = "Please verify your e-mail address before you continue."
								} else {
									user.groups = groups
									response.user = user
									user.token = accessToken
									setUser(user)
								}
								
								callback(response)
							}
						});
					})
				}
			});
		},
	
		onFailure: function(err) {
			response.error = (err.message || JSON.stringify(err));
			callback(response)
		},
	});

	/*return setUser({
		'name': ''
	})*/

	return false
}

export const parseUserAttributes = (err, result) => {

	if(typeof window !== 'undefined') {
		if (!err) {
			for(let i=0; i<result.length; i++) {
				let field = result[i];
				switch(field.getName()) {
					case 'sub':
						setAwsId(field.getValue());
						break;
					case 'name':
						setUserName(field.getValue());
						break;
					case 'email':
						setUserEmail(field.getValue());
						break;
					default:
						break;
				}
			}
		}
	}
}

export const setAwsId = (aws_id) =>{
	let user = getUser();
	user.aws_id = aws_id;
	setUser(user);
}

export const setUserName = (name) =>{
	let user = getUser();
	user.name = name;
	setUser(user);
}

export const setUserEmail = (email) =>{
	let user = getUser();
	user.email = email;
	setUser(user);
}

export const setUserGroups = (groups) =>{
	let user = getUser();
	user.groups = groups;
	setUser(user);
}

export const setuserAccessToken = (token) =>{
	let user = getUser();
	user.token = token;
	setUser(user);
}
/**
 * Is the user logged in?
 * 
 * @returns {boolean}
 */
export const isLoggedIn = () => {
	const user = getUser()
	return user.token !== undefined
}

export const hasAllIncidentAccess = () => {
	const user = getUser()
	if(typeof(user.userType) != 'undefined') {
		if(user.userType == 'Regular') {
			return true
		}
	}
	return false;
}

export const hasDashboardAccess = () => {
	const user = getUser()
	if(typeof(user.userType) != 'undefined') {
		if(user.userType == 'Regular') {
			return true
		}
	}
	return false;
}

export const hasNDDAccess = () => {
	const user = getUser()
	if(typeof(user.userType) != 'undefined') {
		if(user.userType == 'NDD only' || user.userType == 'Regular') {
			return true
		}
	}
	return false;
}

export const redirectedToDefault = () => {
	let user = getUser();
	if(typeof user.userType !== 'undefined'){
		if(user.userType === "Regular"){
			navigate(`/incident-map`)
		}else if (user.userType === "NDD only"){
			navigate(`/network-disruption-database`)
		}else{
			navigate(`/incident-map`)
		}
	}else{
		navigate(`/`)
	}
}


/**
 * Check if the current user is an admin
 * @returns {boolean}
 */
export const isAdmin = () => {
	const user = getUser()
	
	if(typeof(user.groups) != 'undefined') {
		return user.groups.includes('Administrator')
	}
	return false;
}

/**
 * Log out the user
 * @param {func} callback 
 */
export const logout = () => {
	setUser({})
	if (typeof window !== 'undefined') {
		navigate(config.aws.auth_domain+"/logout?client_id="+
		config.aws.client_id+"&logout_uri="+config.base_url+"/logout-callback");

	}
}

/**
 * Register the user in AWS
 * @param {string} username 
 * @param {string} password 
 * @param {string} password_repeat 
 * @param {string} name 
 * @param {func} callback 
 * @returns 
 */
export const register = (username, password, password_repeat, name, callback) => {
	let result = {
		success: false,
		error: "",
		message: "",
		user: {}
	}

	if(password != password_repeat) {
		result.error = "The passwords do not match"	
	}
	let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
	if(strongPassword.test(password) == false) {
		result.error = "The password needs to contain at least 8 characters, a capital letter, a small letter, a number and a symbol"
	}
	
	if(username.substring(username.length - 10) !== "@postnl.nl" && username.substring(username.length - 11) !== "@typify.com"
	&& username.substring(username.length - 11) !== "@postnl.com" && username.substring(username.length - 15) !== "@spring-gds.com") {
		result.error = "You need to use a @postnl.nl e-mail address"
	}

	if(result.error != "") {
		result.success = false

		callback(result)
		return;
	}

	const userPool = getUserPool()

	let attributeList = []

	let dataEmail = {
		'Name': 'email',
		'Value': username
	}
	let dataName = {
		'Name': 'name',
		'Value': name
	}

	const attributeEmail = new CognitoUserAttribute(dataEmail)
	const attributeName = new CognitoUserAttribute(dataName)

	attributeList.push(attributeEmail)
	attributeList.push(attributeName)

	const _username = name.replace(/[^a-zA-Z]+/g, '');
	userPool.signUp(_username, password, attributeList, null, function(err, result) {
		let response = {
			success: false,
			message: '',
			error: '',
			user: {}
		}

		if(err) {
			response.error = err.message || JSON.stringify(err)
			response.success = false;
		} else {
			let cognitoUser = result.user
			response.user = cognitoUser
			response.success = true
			response.message = "Successfully registered! Please check your e-mail address and enter the verification code below."
		}
		
		callback(response)
	})
}

/**
 * resend the validation code to the user email adres from cognito
 * 
 * @param {string} username 
 * @param {func} callback 
 */
export const resendCode = (username, callback) => {
	const cognitoUser = getCognitoUser(username)
	
	cognitoUser.resendConfirmationCode(function(err, result) {
		let response = {
			success: false,
			error: '',
			message: ''
		}

		if (err) {
			response.error = err.message || JSON.stringify(err)	
		} else {
			response.success = true
			response.message = "Confirmation code has been re-send to your e-mail address"
		}
		callback(response)
	})
}

export const handleReset = (username, callback) => {
	const cognitoUser = getCognitoUser(username)
	
	const params = {
		'ClientId': config.aws.clientId,
		'Username': username
		//'SecretHash': CryptoJS.HmacSHA256(username + config.aws.clientId, config.aws.clientSecret).toString(CryptoJS.enc.Base64) // we dont have client secret
	}
	cognitoUser.forgotPassword(
		{
		onSuccess: function(result) {
			//callback(result.CodeDeliveryDetails.DeliveryMedium)
			console.log({result})
		},
		onFailure: function(err) {
			console.log(err)
			return callback(false)
		},
		inputVerificationCode: function(result) {
			callback(result.CodeDeliveryDetails.DeliveryMedium)
		}
	})
}

export const updatePassword = (username, password, code, callback) => {
	const cognitoUser = getCognitoUser(username)

	const params = {
		'ClientId': config.aws.clientId,
		'Username': username,
		'ConfirmationCode': parseInt(code),
		'Password': password
	}

	cognitoUser.confirmPassword(code, password, {
		onSuccess: function(result) {
			callback(true)
		},
		onFailure: function(err) {
			console.log(err)
			callback(false, err)
		}
	});
}

/**
 * Check the given code to confirm an account and its email address
 * @param {string} username 
 * @param {string} code 
 * @param {func} callback 
 */
export const confirmAccount = (username, code, callback) => {
	const cognitoUser = getCognitoUser(username)

	cognitoUser.confirmRegistration(code, true, function(err, result) {
		let response = {
			success: false,
			error: '',
			message: ''
		}
		if( err ) {
			response.error = err.message || JSON.stringify(err)
		} else {
			response.success = true
			response.message = "Your account has been activated! You can now login"
		}
		callback(response)
	})
}

/**
 * Generate CognitoUser object to use in webservice calls to cognito
 * @param {string} username 
 * @returns 
 */
 export const getCognitoUser = (username) => {
	const userPool = getUserPool()
	const userData = {
		Username: username,
		Pool: userPool
	}

	return new CognitoUser(userData)
}

/**
 * Generate CognitoUserPool to use in Cognito API Calls
 * 
 * @returns CognitoUserPool
 */
const getUserPool = () => {
	return new CognitoUserPool({
		UserPoolId: config.aws.user_pool_id,
		ClientId: config.aws.client_id
	})
}